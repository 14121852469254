'use strict'

angular
	.module('cb.mailer', [])
	.controller('MailerContentController', ["$scope", "$modal", "MailingService", "NotificationService", function ($scope, $modal, MailingService, NotificationService) {

		$scope.PageNumber = 1;
		$scope.limitTo = 15;

		$scope.MailerSequence = [{
			Label: "1st Mailing",
			Value: 1
		},
		{
			Label: "1st Follow-up",
			Value: 2
		},
		{
			Label: "2nd Follow-up",
			Value: 3
		},
		{
			Label: "3rd Follow-up",
			Value: 4
		},
		{
			Label: "4th Follow-up",
			Value: 5
		},
		{
			Label: "Other",
			Value: 0
		},
		];

		$scope.$watch('PageNumber', function () {
			$scope.retriveMailerContent();
		});

		$scope.retriveMailerContent = function () {
			var data = {
				Pagination: {
					StartPage: $scope.PageNumber - 1,
					PageSize: $scope.limitTo
				}
			};
			$scope.GetMailerContentPromise = MailingService.GetMailerContent(data);
			$scope.GetMailerContentPromise.then(function (output) {
				$scope.MailerContent = output.data.MailerOutputList;
				$scope.Pagination = output.data.Pagination;
			});
		};
		$scope.editMailerEntry = function (Mailer) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editMailerContentModal.html',
				controller: 'EditMailerContentModalCtrl',
				resolve: {
					MailerEntry: function () {
						return Mailer;
					},
					MailerSequence: function () {
						return $scope.MailerSequence;
					}
				}
			});
			modalInstance.result.then(function () {
				NotificationService.alert("Mailer Entry Edited", "Mailer Entry was edited successfully.", "success", {});
				$scope.retriveMailerContent();
			});
		};

		$scope.addNewMailerEntry = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'newMailerContentModal.html',
				controller: 'NewMailerContentModalCtrl',
				resolve: {
					MailerSequence: function () {
						return $scope.MailerSequence;
					}
				}
			});
			modalInstance.result.then(function () {
				NotificationService.alert("New Mailer Created", "New mailer was created successfully.", "success", {});
				$scope.retriveMailerContent();
			});
		};

		$scope.deleteMailer = function (Id) {
			NotificationService.confirm("Mailer Confirmation.", "Are you sure that you want to delete this mailer?", {}).get().on('pnotify.confirm', function () {
				$scope.DeleteMailerEntryPromise = MailingService.DeleteMailerEntry({
					MailerEntryId: Id
				});
				$scope.DeleteMailerEntryPromise.then(function (output) {
					NotificationService.alert("Mailer Entry Deleted", "The Mailer Entry was successfully deleted.", "success", {});
					$scope.retriveMailerContent();
				});
			});
		};
	}])
	.controller('NewMailerContentModalCtrl', ["$scope", "$modalInstance", "MailingService", "MailerSequence", function ($scope, $modalInstance, MailingService, MailerSequence) {
		$scope.NewMailer = {};
		$scope.MailerSequence = MailerSequence;
		$scope.LastMAilingCount = null;

		$scope.create = function () {
			var data;
			$scope.NewMailerEntryPromise = MailingService.CreateNewMailerEntry($scope.NewMailer);
			$scope.NewMailerEntryPromise.then(function () {
				$modalInstance.close();
			});
		};

		$scope.UpdateDefault = function () {
			switch ($scope.NewMailer.MailingCount) {
				case 1:
					$scope.NewMailer.FirstMailingDefaultBU = true;
					break;
				case 2:
					$scope.NewMailer.FollowUpDefaultBU1st = true;
					break;
				case 3:
					$scope.NewMailer.FollowUpDefaultBU2nd = true;
					break;
				case 4:
					$scope.NewMailer.FollowUpDefaultBU3rd = true;
					break;
				case 5:
					$scope.NewMailer.FollowUpDefaultBU4th = true;
					break;
				default:
					break;
			}
			if ($scope.LastMailingCount != null) {
				switch ($scope.LastMailingCount) {
					case 1:
						$scope.NewMailer.FirstMailingDefaultBU = false;
						break;
					case 2:
						$scope.NewMailer.FollowUpDefaultBU1st = false;
						break;
					case 3:
						$scope.NewMailer.FollowUpDefaultBU2nd = false;
						break;
					case 4:
						$scope.NewMailer.FollowUpDefaultBU3rd = false;
						break;
					case 5:
						$scope.NewMailer.FollowUpDefaultBU4th = false;
						break;
					default:
						break;
				}
			}
			$scope.LastMailingCount = $scope.NewMailer.MailingCount;
		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('EditMailerContentModalCtrl', ["$scope", "$modalInstance", "MailingService", "MailerEntry", "MailerSequence", function ($scope, $modalInstance, MailingService, MailerEntry, MailerSequence) {
		$scope.EditMailer = angular.copy(MailerEntry);
		$scope.MailerSequence = MailerSequence;
		$scope.LastMailingCount = null;

		$scope.save = function () {
			$scope.EditMailerEntryPromise = MailingService.EditMailerEntry($scope.EditMailer);
			$scope.EditMailerEntryPromise.then(function () {
				$modalInstance.close();
			});
		};

		$scope.UpdateDefault = function () {
			switch ($scope.EditMailer.MailingCount) {
				case 1:
					$scope.EditMailer.FirstMailingDefaultBU = true;
					break;
				case 2:
					$scope.EditMailer.FollowUpDefaultBU1st = true;
					break;
				case 3:
					$scope.EditMailer.FollowUpDefaultBU2nd = true;
					break;
				case 4:
					$scope.EditMailer.FollowUpDefaultBU3rd= true;
					break;
				case 5:
					$scope.EditMailer.FollowUpDefaultBU4th = true;
					break;
				default:
					break;
			}
			if ($scope.LastMailingCount != null) {
				switch ($scope.LastMailingCount) {
					case 1:
						$scope.EditMailer.FirstMailingDefaultBU = false;
						break;
					case 2:
						$scope.EditMailer.FollowUpDefaultBU1st = false;
						break;
					case 3:
						$scope.EditMailer.FollowUpDefaultBU2nd = false;
						break;
					case 4:
						$scope.EditMailer.FollowUpDefaultBU3rd = false;
						break;
					case 5:
						$scope.EditMailer.FollowUpDefaultBU4th = false;
						break;
					default:
						break;
				}
			}
			$scope.LastMailingCount = $scope.EditMailer.MailingCount;
		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.filter('MailerSequenceFilter', function () {
		return function (value, input) {
			if (input == undefined || value == undefined)
				return value;
			for (var i = 0; i < input.length; i++) {
				if (input[i].Value == value)
					return input[i].Label;
			}
			return "";
		}
	})
	.controller('BuilderListController', ["$scope", "$modal", "MailingService", "NotificationService", "PickListService", "$timeout", function ($scope, $modal, MailingService, NotificationService, PickListService, $timeout) {

		$scope.init = function () {
			$scope.BuilderListPageNumber = 1;
			$scope.selectedFilter = 1;
			$scope.NameFilter;
			$scope.BatchFilter = 'AllAgents';
			$scope.timeout = $timeout(function () { });
			$scope.tabs = [
				{
					Id: 1,
					Name: 'A-C',
					Filter: ['a', 'b', 'c'],
					Enabled: true
				},
				{
					Id: 2,
					Name: 'D-F',
					Filter: ['d', 'e', 'f'],
					Enabled: true
				},
				{
					Id: 3,
					Name: 'G-I',
					Filter: ['g', 'h', 'i'],
					Enabled: true
				},
				{
					Id: 4,
					Name: 'J-L',
					Filter: ['j', 'k', 'l'],
					Enabled: true
				},
				{
					Id: 5,
					Name: 'M-O',
					Filter: ['m', 'n', 'o'],
					Enabled: true
				},
				{
					Id: 6,
					Name: 'P-S',
					Filter: ['p', 'q', 'r', 's'],
					Enabled: true
				},
				{
					Id: 7,
					Name: 'T-V',
					Filter: ['t', 'u', 'v'],
					Enabled: true
				},
				{
					Id: 8,
					Name: 'W-Z',
					Filter: ['w', 'x', 'y', 'z'],
					Enabled: true
				},
				{
					Id: 9,
					Name: '1-!',
					Filter: ['chars'],
					Enabled: true
				},
				{
					Id: 10,
					Name: 'All',
					Filter: 'all',
					Enabled: true
				}
			];

			$scope.GetPicksPromise = PickListService.getPickListByPickListName(["BuilderGroup"]);
			$scope.GetPicksPromise.then(function (output) {
				$scope.Params = output.data.PickListEntries;
			});
		};

		$scope.$watch('BuilderListPageNumber', function () {
			$scope.getBuilderList();
		});

		$scope.selectFilter = function (id, allow) {
			if (allow) {
				$scope.selectedFilter = id;
				if ($scope.BuilderListPageNumber == 1)
					$scope.getBuilderList();
				else
					$scope.BuilderListPageNumber = 1;
			};
		};

		$scope.filterItems = function () {
			$timeout.cancel($scope.timeout);
			$scope.timeout = $timeout(() => {
				if ($scope.BuilderListPageNumber == 1)
					$scope.getBuilderList();
				else
					$scope.BuilderListPageNumber = 1;
			}, 500);
		};

		$scope.SelectBatchType = function (type) {
			$scope.BatchFilter = type;
			if ($scope.BuilderListPageNumber == 1)
				$scope.getBuilderList();
			else
				$scope.BuilderListPageNumber = 1;
		};

		$scope.getBuilderList = function () {
			if ($scope.selectedFilter == 0)
				$scope.selectedFilter = 1;

			var data = {
				BatchFilter: $scope.BatchFilter,
				NameFilter: $scope.NameFilter || null,
				BuilderGroupFilter: $scope.tabs.find(tab => tab.Id == $scope.selectedFilter).Filter,
				Pagination: {
					StartPage: $scope.BuilderListPageNumber - 1,
					PageSize: 15
				}
			};

			$scope.GetBuilderListPromise = MailingService.GetBuilderList(data);
			$scope.GetBuilderListPromise.then(function (output) {
				$scope.BuilderList = output.data.BuilderList;
				$scope.Pagination = output.data.Pagination;

				if (output.data.Tabs)
					$scope.tabs.forEach((tab) => { tab.Enabled = output.data.Tabs.find(item => item.Name == tab.Name).Enabled });
				else
					$scope.tabs.forEach((tab) => { tab.Enabled = true });

				if (!$scope.tabs.find(tab => tab.Id == $scope.selectedFilter).Enabled) {
					$scope.selectedFilter = $scope.tabs.filter(tab => tab.Enabled).length > 0 ?
						$scope.tabs.filter(tab => tab.Enabled)[0].Id : 0;
					if ($scope.selectedFilter > 0)
						$scope.selectFilter($scope.selectedFilter, true);
					else
						NotificationService.alert("No Results", "There are no results with that search text.", "warning", {});
				};
			});
		};

		$scope.createNewAgent = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'newAgentModal.html',
				controller: 'NewAgentModalCtrl',
				resolve: {
					Params: function () {
						return $scope.Params;
					}
				}
			});
			modalInstance.result.then(function () {
				NotificationService.alert("New Agent Created", "New agent was created successfully.", "success", {});
				$scope.getBuilderList();
			});
		};

		$scope.editAgent = function (agent) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'editAgentModal.html',
				controller: 'EditAgentModalCtrl',
				resolve: {
					Params: function () {
						return $scope.Params;
					},
					Agent: function () {
						return agent;
					}
				}
			});
			modalInstance.result.then(function () {
				NotificationService.alert("Agent Edited", "Agent was editied successfully.", "success", {});
				$scope.getBuilderList();
			});
		};

		$scope.reconcileAgent = function (agent) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'views/modal-templates/reconcile-agent.html',
				controller: 'ReconcileAgentModalController',
				resolve: {
					Data: function () {
						return {
							Agent: agent
						}
					}
				}
			});

			modalInstance.result.then(function () {
				$scope.getBuilderList();
			});
		};
	}])
	.controller('NewAgentModalCtrl', ["$scope", "$modalInstance", "MailingService", "Params", function ($scope, $modalInstance, MailingService, Params) {
		$scope.NewAgent = {
			Address: {}
		};
		$scope.Params = Params;

		$scope.create = function () {
			$scope.NewAgentPromise = MailingService.CreateAgent($scope.NewAgent);
			$scope.NewAgentPromise.then(function () {
				$modalInstance.close();
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('EditAgentModalCtrl', ["$scope", "$modalInstance", "MailingService", "Agent", "Params", function ($scope, $modalInstance, MailingService, Agent, Params) {
		$scope.EditAgent = angular.copy(Agent);
		$scope.Params = Params;

		for (var i = 0; i < $scope.Params.length; i++) {
			if ($scope.EditAgent.BuilderGroup == $scope.Params[i]) {
				$scope.EditAgent.BuilderGroup = $scope.Params[i];
				break;
			}
		}

		$scope.save = function () {
			$scope.EditAgentPromise = MailingService.EditAgent($scope.EditAgent);
			$scope.EditAgentPromise.then(function () {
				$modalInstance.close();
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])

	.controller('MailingDiaryController', ["$scope", "MailingService", "NotificationService", function ($scope, MailingService, NotificationService) {
		$scope.ImportBatchListPageNumber = 1;
		$scope.FirstMailingListPageNumber = 1;
		$scope.FollowUpMailingListPageNumber = 1;
		// $scope.changeTab = function (tab) {
		// 	switch (tab) {
		// 		case 'importBatch':
		// 			$scope.ImportBatchListPageNumber = 1;
		// 			break;
		// 		case 'firstMailing':
		// 			$scope.FirstMailingListPageNumber = 1;
		// 			break;
		// 		case 'followUpMailing':
		// 			$scope.FollowUpMailingListPageNumber = 1;
		// 			break;
		// 	};
		// 	$scope.active = tab;
		// };


		$scope.$watch('ImportBatchListPageNumber', function (data) {
			if (data != undefined)
				$scope.retriveImportBatchList();
		}, true);

		$scope.$watch('FirstMailingListPageNumber', function (data) {
			if (data != undefined)
				$scope.retriveFirstMailingList();
		}, true);

		$scope.$watch('FollowUpMailingListPageNumber', function (data) {
			if (data != undefined)
				$scope.retriveFollowUpMailingList();
		}, true);


		$scope.retriveImportBatchList = function () {
			var data = {
				Pagination: {
					StartPage: $scope.ImportBatchListPageNumber - 1,
					PageSize: 10
				}
			};
			$scope.ImportBatchPromise = MailingService.GetImportBatch(data);
			$scope.ImportBatchPromise.then(function (output) {
				$scope.ImportBatchList = output.data.ImportBatchList;
				$scope.ImportBatchPagination = output.data.Pagination;
			});
		};

		$scope.DeleteImportBatch = function (Id) {
			if (confirm("Are you sure you want to delete this import batch?")) {
				$scope.DeleteImportBatchPromise = MailingService.DeleteImportBatch(Id);
				$scope.DeleteImportBatchPromise.then(
					function (output) {
						$scope.retriveImportBatchList();
					}
				);
			}


		};

		$scope.retriveFirstMailingList = function () {
			var data = {
				Pagination: {
					StartPage: $scope.FirstMailingListPageNumber - 1,
					PageSize: 10
				}
			};
			$scope.FirstMailingPromise = MailingService.GetFirstMailing(data);
			$scope.FirstMailingPromise.then(function (output) {
				$scope.FirstMailingList = output.data.FirstMailingList;
				$scope.FirstMailingPagination = output.data.Pagination;
			});
		};

		$scope.goToFirstMailing = function (Id) {
			//
		};

		$scope.retriveFollowUpMailingList = function () {
			var data = {
				Pagination: {
					StartPage: $scope.FollowUpMailingListPageNumber - 1,
					PageSize: 10
				}
			};
			$scope.FollowUpMailingPromise = MailingService.GetFollowUpMailing(data);
			$scope.FollowUpMailingPromise.then(function (output) {
				$scope.FollowUpMailingList = output.data.FollowUpMailingList;
				$scope.FollowUpMailingPagination = output.data.Pagination;
			});
		};

		$scope.goToFollowUpMailing = function (Id) {
			//
		};
	}])

	.controller('OtherMailingController', ["$scope", "ImportTemplateService", "MailingService", "PickListService", "NotificationService", "DownloadFileHelper", "$timeout", "$location", function ($scope, ImportTemplateService, MailingService, PickListService, NotificationService, DownloadFileHelper, $timeout, $location) {
		$scope.Today = new Date();
		$scope.Progressing = false;
		$scope.Downloading = false;
		$scope.progressValue = 0;
		$scope.success = 0;
		$scope.Falled = 0;
		$scope.BatchesSuccess = 0;
		$scope.BatchesFailed = 0;
		$scope.openPopups = {};
		$scope.RecordType = 'prospect';
		$scope.MalingBatch = {
			CurrentBatch: 0,
			NextBatch: 0,
			NextBatchSize: 0,
			BatchSize: 0,
			MailingSelectionSize: 0,
			NumberOfBatches: 0,
			ProspectsFound: 0,
			CurrentBatchSucsessfull: null,
		}

		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.init = function () {
			$scope.listOpen = false;
			$scope.ProspectRecordSelection = {};
			$scope.ProspectRecordSelection.DoNotMail = true;
			$scope.ProspectRecordSelection.ExcludeRecordsMailedInLast3Months = true;
			$scope.FileNames = [];
			$scope.FolderName = '';
			$scope.FileIteration = 0;
			$scope.DocsForDownload = [];
			$scope.DownloadRequests = [];
			$scope.RequestStart = null;
			$scope.RequestEnd = null;
			$scope.BuildingFilesStart = null;
			$scope.DownloadingFilesStart = null;
			$scope.Action = "List";
			$scope.Paginationinfo = {
				HasPageBack: false,
				HasPageForwards: true,
				PageSize: 10,
				StartPage: 0,
			};
			$scope.UpdateSelectedRecords = {};
			$scope.UpdateSelectedRecords.MailingDate = new Date();

			$scope.GetPicksPromise = PickListService.getPickListByPickListName(["ddmSiteType", "BuilderGroup"]);
			$scope.GetPicksPromise.then(function (output) {
				$scope.Picks = output.data.PickListEntries;
			});

			$scope.GetTemplatesByTypePromise = ImportTemplateService.getTemplatesByType('prospects');
			$scope.GetTemplatesByTypePromise.then(function (output) {
				$scope.DataTemplates = output.data.TemplateList;
				$scope.DataTemplates.unshift({ Id: 0, TemplateName: 'Manual Entry' });
			});
		};

		$scope.Submit = function () {
			if ($scope.UpdateSelectedRecords.MailingDate > moment(new Date()))
				NotificationService.alert('Error', 'You cannot set a mailing date for a date in the future!', 'error', {});
			else
				$scope.GetMailingList();
		}

		$scope.goToURL = function (Id) {
			if ($scope.FilterListTable == "lead")
				$location.path('enquiry-details/' + Id);
			else
				$location.path('prospect-details/' + Id);
		}

		//list
		$scope.decrement = function () {
			$scope.Paginationinfo.StartPage--;
			if ($scope.listOpen) {
				$scope.GetMailingList();
			}
		};

		$scope.increment = function () {
			$scope.Paginationinfo.StartPage++;
			if ($scope.listOpen) {
				$scope.GetMailingList();
			}
		};
		$scope.GetMailingList = function () {
			$scope.listOpen = true;
			$scope.prospects = null;
			var data = {
				ProspectRecordSelection: $scope.ProspectRecordSelection,
				Paginationinfo: $scope.Paginationinfo,
				RecordType: $scope.RecordType,
			}
			$scope.maillingList = MailingService.GetMailingSelectionList(data)
			$scope.maillingList.then((output) => {
				$scope.FilterListTable = $scope.RecordType;
				$scope.prospects = output.data.ProspectOutput;
				$scope.Paginationinfo = output.data.Paginationinfo;
				switch ($scope.Action) {
					case 'Export':
						$scope.StopAtRecord = $scope.prospects.length;
						ExportToCSV();
						break;
					case 'MailMerge':
						$scope.StopAtRecord = $scope.prospects.length;
						$scope.StartMailMerge();
						break;
					default:
						break;
				}
			}, (error) => {
				NotificationService.alert('Error Occurred', 'Error Occurred when tring to get Mailing Selection List', 'failed', {})
			})
		}

		//export
		function ExportToCSV() {
			var list = [];
			StoppedAtRecords()
			$scope.StoppedAtRecords.forEach(function (current) {
				delete current.$$hashKey;
				var temp = {};
				for (var k in current) {
					temp[k] = current[k];
				}
				list.push(temp);
			});
			promiseJSONToCSVConvertor(JSON.stringify(list), "Other Mailing Export", true);
			NotificationService.confirm("Mailer Confirmation", "Do you want to mark this mailing as complete?", {}).get().on('pnotify.confirm', function () {
				updateProspects();
			});
		}

		function promiseJSONToCSVConvertor(JSONData, reportTitle, showLabel) {
			var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData, CSV = '';
			CSV += reportTitle + '\r\n\n';

			if (showLabel) {
				var row = "";
				for (var index in arrData[0]) {
					index = index.replace(/([a-z])([A-Z])/g, '$1 $2');
					row += index + ',';
				};
				row = row.slice(0, -1);
				CSV += row + '\r\n';
			};

			for (var i = 0; i < arrData.length; i++) {
				var row = "";
				for (var index in arrData[i]) {
					row += '"' + arrData[i][index] + '",';
				};
				row.slice(0, row.length - 1);
				CSV += row + '\r\n';
			};

			if (CSV == '')
				return;

			var fileName = "Logs_";

			if (reportTitle)
				fileName += reportTitle.replace(/\s/g, "_");

			downloadFile(fileName + '.csv', 'text/csv', CSV)
		};

		function downloadFile(filename, mimeType, csvContent) {
			filename = filename.replace(/['"]+/g, '');
			var blob = new Blob([csvContent]);
			var needsClick = true;

			if (navigator.appVersion.toString().indexOf('.NET') > 0)
				window.navigator.msSaveBlob(blob, filename);

			else if (window.URL) {
				var evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", null, null);

				$timeout(function () {
					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					var url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = filename;
					a.click();
				});
				//window.URL.revokeObjectURL(url);
			}
			else {
				if (document.createEvent) {
					var link = document.createElement("a");
					link.setAttribute("href", encodeURI("data:" + mimeType + "," + csvContent));
					link.setAttribute("download", filename);
					var e = document.createEvent('MouseEvents');
					e.initEvent('click', true, true);
					needsClick = link.dispatchEvent(e);
				};
				if (needsClick)
					window.navigator.msSaveBlob(blob, filename);
			};
		};

		//mail merge
		$scope.StartMailMerge = function () {
			StoppedAtRecords();
			$scope.SetUpMalingBatch();
		};

		function StoppedAtRecords() {
			$scope.StoppedAtRecords = [];
			if ($scope.StopAtRecord == $scope.prospects.length) {
				$scope.StoppedAtRecords = $scope.prospects;
			}
			else {
				for (var i = 0; i < $scope.StopAtRecord && i < $scope.prospects.length; i++) {
					$scope.StoppedAtRecords.push($scope.prospects[i]);
				}
				if ($scope.StopAtRecord > $scope.prospects.length)
					$scope.StopAtRecord = $scope.prospects.length;
			}
		}

		$scope.SetUpMalingBatch = function () {
			$scope.progressValue = 0;
			$scope.success = 0;
			$scope.Falled = 0;
			$scope.BatchesSuccess = 0;
			$scope.FileNames = [];
			$scope.FolderName = '';
			$scope.FileIteration = 0;
			$scope.DocsForDownload = [];
			$scope.DownloadRequests = [];
			$scope.RequestStart = null;
			$scope.RequestEnd = null;
			$scope.BuildingFilesStart = null;
			$scope.DownloadingFilesStart = null;
			$scope.BatchesFailed = 0;
			$scope.MalingBatch = {};
			$scope.listOpen = false;
			$scope.Progressing = true

			//NEED TO MANUALLY CREATE THE MAIL BATCH HERE FROM THE EXISTING LIST OF PROSPECTS
			var maxProspects = 10, batchInfo = {};
			if ($scope.StoppedAtRecords.length < maxProspects) {
				batchInfo = {
					BatchSize: $scope.StoppedAtRecords.length,
					NextBatchSize: 0,
					CurrentBatch: 0,
					NumberOfBatches: 0,
					MailingSelectionSize: $scope.StoppedAtRecords.length,
					ProspectsFound: 0,
					CurrentBatchSucsessfull: false
				};
			} else {
				batchInfo = {
					NumberOfBatches: parseInt($scope.StoppedAtRecords.length / maxProspects),
					MailingSelectionSize: $scope.StoppedAtRecords.length,
					BatchSize: maxProspects,
					CurrentBatch: 0,
					ProspectsFound: 0,
					CurrentBatchSucsessfull: false
				};
			};

			$scope.MalingBatch = batchInfo;
			$scope.ExecuteMailingSelection($scope.MalingBatch);
			setTimeout(() => {
				InitCircles();
			}, 10);
		};

		$scope.ExecuteMailingSelection = function (MalingBatch) {
			if (!$scope.FolderName) {
				$scope.TotalProspects = $scope.StoppedAtRecords.length;
				$scope.FolderName = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16);
				});
			};

			$scope.FileIteration++;
			var data = null;
			if ($scope.RecordType == 'lead') {
				var prosTo = $scope.FileIteration * 10 || 10, prosFrom = $scope.FileIteration == 1 ? 0 : prosTo - 10, file = form.templateUpload != undefined ? form.templateUpload.files[0] : null, data = {
					Action: 'MailMerge',
					FolderName: $scope.FolderName,
					FileIteration: $scope.FileIteration,
					ProspectRecordSelection: {},
					UpdateSelectedRecords: {},
					MalingBatch: MalingBatch,
					Leads: $scope.StoppedAtRecords.slice(prosFrom, prosTo).map(pro => pro.Id).join(', ')
				};
			}
			else {
				var prosTo = $scope.FileIteration * 10 || 10, prosFrom = $scope.FileIteration == 1 ? 0 : prosTo - 10, file = form.templateUpload != undefined ? form.templateUpload.files[0] : null, data = {
					Action: 'MailMerge',
					FolderName: $scope.FolderName,
					FileIteration: $scope.FileIteration,
					ProspectRecordSelection: {},
					UpdateSelectedRecords: {},
					MalingBatch: MalingBatch,
					Prospects: $scope.StoppedAtRecords.slice(prosFrom, prosTo).map(pro => pro.Id).join(', ')
				};
			}

			if (!$scope.BuildingFilesStart)
				$scope.BuildingFilesStart = new Date();

			$scope.RequestStart = new Date();
			$scope.GetMailingSeletionPromise = MailingService.GetMailingSelectionforProspects(data, file);
			$scope.GetMailingSeletionPromise.then(function (output) {
				$scope.RequestEnd = new Date();
				SortTimer('Building');

				var textDecoder = new TextDecoder('utf-8');
				output.data = JSON.parse(textDecoder.decode(output.data));
				$scope.MalingBatch = output.data.BatchInfo;
				$scope.progressValue = ((100 / $scope.TotalProspects) * ($scope.FileIteration * 10));
				AdjustProgressCircles($scope.progressValue, 'Building');

				if ($scope.MalingBatch.CurrentBatchSucsessfull) {
					BatchSuccess(MalingBatch.BatchSize);
					if (output.data.DownloadFile.FilePath != null)
						$scope.FileNames.push(output.data.DownloadFile.FilePath);
				} else {
					BatchFailed(MalingBatch.BatchSize);
				}

				if (($scope.FileIteration * 10) <= $scope.StoppedAtRecords.length) {
					$scope.ExecuteMailingSelection($scope.MalingBatch);
				} else {
					$scope.progressValue = 100;
					AdjustProgressCircles($scope.progressValue, 'Building');
					if ($scope.FileNames != null) {
						$scope.DownloadFile();
					}
				}

			});
		};

		function BatchSuccess(BatchSize) {
			$scope.BatchesSuccess++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesSuccess
			$scope.success = value;
		};

		function BatchFailed(BatchSize) {
			$scope.BatchesFailed++;
			var total = $scope.BatchesSuccess + $scope.BatchesFailed;
			let value = (100 / total) * $scope.BatchesFailed;
			$scope.Falled = value;
		};

		$scope.DownloadFile = function (data) {
			if (!data) {
				$scope.DownloadingFilesStart = new Date();
				$scope.Downloading = true;
				$scope.DownloadsDone = 1;

				var NamesFrom = 0, NamesTo = 20, Names = $scope.FileNames.map(file => [file.slice(0, file.length - 5), '_Final', file.slice(file.length - 5)].join('')),
					BatchesRemaining = parseFloat((Names.length / 20).toFixed(2));

				for (var i = 0; i < BatchesRemaining; i++) {
					var slice = {
						Files: Names.slice(NamesFrom, NamesTo),
						Folder: $scope.FolderName,
						FinalDownload: false
					};
					NamesFrom = NamesTo;
					NamesTo += 20;
					$scope.DownloadRequests.push(slice);
				};

				$scope.DownloadRequests[$scope.DownloadRequests.length - 1].FinalDownload = true;
				$scope.TotalDownloadRequests = $scope.DownloadRequests.length;


				if ($scope.DownloadRequests.length)
					$scope.DownloadFile($scope.DownloadRequests[0]);
			} else {
				data.FileName = form.templateUpload.files[0].name;
				$scope.DownloadStart = new Date();
				$scope.DownlodeBatchesPromice = MailingService.DownlodeBatches(data);
				$scope.DownlodeBatchesPromice.then((output) => {
					$scope.DownloadEnd = new Date();
					SortTimer('Downloading');
					$scope.downloadingProgressValue = ((100 / $scope.TotalDownloadRequests) * $scope.DownloadsDone);
					AdjustProgressCircles($scope.downloadingProgressValue, 'Downloading');
					$scope.DownloadsDone++;

					$scope.DocsForDownload.push(new Blob([output.data]))

					if (data.FinalDownload) {
						$scope.DownloadingTimeToComplete = 0;
						SaveFile($scope.DocsForDownload);
						$scope.Downloading = false;
					} else {
						$scope.DownloadRequests.shift();
						$scope.DownloadFile($scope.DownloadRequests[0]);
					};
				}, (error) => {
					NotificationService.alert('Download Error', 'An error occured when attempting to download the batches.', 'failed', {})
					$scope.inProgress = false;
				});
			};
			$scope.inProgress = false;
		};

		// mail merge wheel
		function SortTimer(type) {
			if (type == 'Building') {
				var responseSpeed = (($scope.RequestEnd - $scope.RequestStart) / 1000);
				$scope.BuildingTimeToComplete = Math.floor((responseSpeed * (($scope.MalingBatch.MailingSelectionSize - $scope.MalingBatch.ProspectsFound) / 10)));
				$scope.BuildingTimeElapsed = Math.floor(((new Date() - $scope.BuildingFilesStart) / 1000));
			} else {
				var downloadSpeed = (($scope.DownloadEnd - $scope.DownloadStart) / 1000);
				$scope.DownloadingTimeToComplete = Math.floor((downloadSpeed * ($scope.TotalDownloadRequests - $scope.DownloadRequests.length)));
				$scope.DownloadingTimeElapsed = Math.floor(((new Date() - $scope.DownloadingFilesStart) / 1000));
			};
		};

		function InitCircles() {
			var circles = document.querySelectorAll('circle');
			if (circles.length) {
				circles.forEach((circle) => {
					var radius = circle.r.baseVal.value;
					var circumference = radius * 2 * Math.PI;

					circle.style.strokeDasharray = `${circumference} ${circumference}`;
					circle.style.strokeDashoffset = `${circumference}`;
				});
			};
		};

		function AdjustProgressCircles(percent, type) {
			var circle = type == 'Building' ? document.getElementById('BuildingProgressCircle') : document.getElementById('DownloadingProgressCircle');
			var radius = circle.r.baseVal.value;
			var circumference = radius * 2 * Math.PI;

			const offset = circumference - percent / 100 * circumference;
			circle.style.strokeDashoffset = offset;
		}

		$scope.back = function () {
			$scope.Progressing = !$scope.Progressing
		}

		function updateProspects() {
			$scope.prospectIds = [];
			for (var i = 0; i < $scope.StoppedAtRecords.length; i++) {
				$scope.prospectIds.push($scope.StoppedAtRecords[i].Id);
			}
			var input = {
				UserId: $scope.User.Id,
				prospectIds: $scope.prospectIds,
				newMailingDate: moment($scope.UpdateSelectedRecords.MailingDate),
				noteContent: $scope.UpdateSelectedRecords.AddNote
			};
			if ($scope.FilterListTable == "lead") {
				input.leadIds = $scope.prospectIds;
			}
			else {
				input.prospectIds = $scope.prospectIds;
			}
			
			$scope.updateOtherMailingPromise = MailingService.UpdateOtherMailing(input);
			$scope.updateOtherMailingPromise.then(function () {
				//DISPLAY SUCCESS MESSAGE
				NotificationService.alert('Completed', 'Follow-up mailing completed.', 'success', {});
			});
		}

		function SaveFile(data) {
			var Zip = new JSZip();

			data.forEach((item, index) => {
				Zip = Zip.file('Doc_' + index + '.docx', item);
			});

			Zip.generateAsync({
				type: "blob"
			}).then(function (content) {
				saveAs(content, "DownloadedBatches.zip");
			});
			NotificationService.confirm("Mailer Confirmation", "Do you want to mark this mailing as complete?", {}).get().on('pnotify.confirm', function () {
				updateProspects();
			});
		}
	}])

	.controller('ReconcileAgentModalController', ["$scope", "$rootScope", "$location", "$routeParams", "$modalInstance", "$timeout", "NotificationService", "MailingService", "ProspectService", "Data", function ($scope, $rootScope, $location, $routeParams, $modalInstance, $timeout, NotificationService, MailingService, ProspectService, Data) {

		$scope.init = function () {
			$scope.BuilderList = [];
			$scope.ProspectList = [];
			$scope.NewAgent = undefined;
			$scope.Agent = Data.Agent;
		};

		//GET ALL AGENTS
		$scope.GetAgents = function(filter) {
			return MailingService.GetBuilderList({ BatchFilter: 'AllAgents', NameFilter: filter, BuilderGroupFilter: null, Pagination: { StartPage: 0, PageSize: 15 } })
				.then(function (output) {
					if (output.data.BuilderList && output.data.BuilderList.length)
						return output.data.BuilderList;
					else {
						NotificationService.alert("No Results", "There are no results with that search text.", "warning", {});	
						return [];
					}
				});
		};

		$scope.Complete = function () {
			$scope.reconcilingBuildersPromise = ProspectService.reconcileBuilders({ CurrentAgentId: $scope.Agent.Id, NewAgentId: $scope.NewAgent.Id });
			$scope.reconcilingBuildersPromise.then(function (output) {
				NotificationService.alert("Reconciliation Complete", "Successfully reconciled these records.", "success", {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert("Reconciliation Error", "There was an error when attempting to reconcile these records.", "error", {});
			});
		};

		$scope.close = function() {
			$modalInstance.dismiss();
		};


	}])

